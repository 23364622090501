import { Component, OnInit} from '@angular/core';
import { CookieService } from "angular2-cookie/core";
import {Router} from "@angular/router";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{

  year:number = new Date().getFullYear();
  accepteAnalyticsCookiesGoogle: boolean = false;
  accepteAnalyticsCookiesBing: boolean = false;

  constructor(private _cookieService: CookieService, private router: Router) {

  }

  ngOnInit(): void {
    // for more about cookies settings that I used: https://tutorialedge.net/typescript/angular/angular-cookies-tutorial/
    document.getElementById('cookies-modal').style.display='block';
    let userCookie = this.getCookie("idiCookie");
    if(userCookie == "true" || userCookie == "false")
    {
        document.getElementById('cookies-modal').style.display='none';  
        this.accepteAnalyticsCookiesGoogle =  (this.getCookie("GACookie") == "true"); 
        this.accepteAnalyticsCookiesBing =  (this.getCookie("BingCookie") == "true"); 

        let dateCookie = new Date(this.getCookie("idiCookieDuration"));
        let today = new Date();
        if(dateCookie == today){
          this._cookieService.removeAll();
          this.router.navigate(['/']);
        } 
    }
     
    
  }
  getCookie(key: string){
    return this._cookieService.get(key);
  }
  setNextYear(){
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let c = new Date(year + 1, month, day);
    return c;
  }
  acceptCookie(): void{
    this._cookieService.put("idiCookie","true");
    this._cookieService.put("GACookie", "true");
    this._cookieService.put("BingCookie", "true");
    this._cookieService.put("idiCookieDuration", this.setNextYear()+"");
    window.location.href = "https://www.idigao.com"; 
  }
  refuseCookie(): void{
    this._cookieService.put("idiCookie","false");
    this._cookieService.put("GACookie", "false");
    this._cookieService.put("BingCookie", "false");
    document.getElementById('cookies-modal').style.display='none';
    window.location.href = "https://www.idigao.com";  
  }
  onChangeGoogleAnalytic(event): void{
     this.accepteAnalyticsCookiesGoogle = event.checked; 
  }
  onChangeBingAnalytic(event): void{
    this.accepteAnalyticsCookiesBing = event.checked; 
 }
 ValidateCookie(event):void{
  this._cookieService.put("idiCookie","true");
  this._cookieService.put("idiCookieDuration", new Date()+"");
  this._cookieService.put("GACookie", this.accepteAnalyticsCookiesGoogle+"");
  this._cookieService.put("BingCookie", this.accepteAnalyticsCookiesBing+"");
  window.location.href = "https://www.idigao.com";  
 }
}
