import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Realisation } from '../../../../shared/models/realisation.model';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-realisation',
  templateUrl: './realisation.component.html',
  styleUrls: ['./realisation.component.css']
})
export class RealisationComponent implements OnInit {

  realisations: Realisation[];
  defaultImg : string = "/assets/img/idigao/defaultRealisation300x200.png";
  modalRealisation: Realisation;
  @ViewChild('myModal') myModal : ElementRef;

  constructor(
    private homeService: HomeService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    //this.homeService.getRealisations().subscribe(realisations => this.realisations = realisations);
    //this.modalRealisation = {titre: "", description: ""};
  }

  /*
  openModal(index: number): void {
    this.modalRealisation = this.realisations[index];
    let modalBoxElement = document.getElementById("modal01");
    if(modalBoxElement) modalBoxElement.style.display = "block";
  }
  */

  openModal(index: number): void {
    this.modalRealisation = this.realisations[index];
    this.renderer.setStyle(this.myModal.nativeElement, 'display', 'block');   
  }

  closeModal(): void {
    this.renderer.setStyle(this.myModal.nativeElement, 'display', 'none');
  }
}
