import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OffreEmploi } from '../../../../shared/models/offreEmploi.model';
import { RecrutementService } from '../../services/recrutement.service';
import { Candidature } from '../../../../shared/models/candidature.model';

@Component({
  selector: 'app-job-offer-view',
  templateUrl: './job-offer-view.component.html',
  styleUrls: ['./job-offer-view.component.css']
})
export class JobOfferViewComponent implements OnInit {

  year:number = new Date().getFullYear();

  objectKeys = Object.keys;
  offreEmploi: OffreEmploi;

  candidatureForm: FormGroup;

  //storage
  cvFileIsUploading = false;
  cvFileUrl: string;
  cvFileUploaded = false;

  @ViewChild('modalIndicationForm') modalIndicationForm;
  indicationMsg: string;
  

  constructor(
    private recrutementService: RecrutementService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    // TODO Ne pas utiliser "key" mais plutot "id"
    const id = this.route.snapshot.params['key'];
    console.log(this.recrutementService.getOffreEmploi(id));
    // this.recrutementService.getOffreEmploi(id).subscribe(offre => this.offreEmploi = offre);
    this.offreEmploi = this.recrutementService.getOffreEmploi(id);
    this.initForm();
    console.log(id);
  }

  initForm() {
    this.candidatureForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  get nom() { return this.candidatureForm.get('nom') }
  get prenom() { return this.candidatureForm.get('prenom') }
  get email() { return this.candidatureForm.get('email') }
  get phone() { return this.candidatureForm.get('phone') }
  get message() { return this.candidatureForm.get('message') }
/*
  onSubmitForm() {
    const nom = this.candidatureForm.get('nom').value;
    const prenom = this.candidatureForm.get('prenom').value;
    const email = this.candidatureForm.get('email').value;
    const phone = this.candidatureForm.get('phone').value;
    const message = this.candidatureForm.get('message').value;

    const newCandidature: Candidature = {
      nom: nom,
      prenom: prenom,
      email: email,
      phone: phone,
      message: message,
      lienCV: null
    };
    
    //storage
    if(this.cvFileUrl && this.cvFileUrl !== '') {
      newCandidature.lienCV = this.cvFileUrl;
    }
    const key = this.route.snapshot.params['key'];
    this.recrutementService.saveCandidature(key ,newCandidature)
      .then(() => {
        this.candidatureForm.reset();
        this.indicationMsg = "Votre candidature a bien été envoyé.";
        this.renderer.setStyle(this.modalIndicationForm.nativeElement, 'display', 'block');
      })
      .catch((error) => {
        this.indicationMsg = "Une erreur est survenue lors de l'envoi du formulaire --> "+error;
        this.renderer.setStyle(this.modalIndicationForm.nativeElement, 'display', 'block');
      });

  }

  //storage
  onUploadFile(file: File) {
    this.cvFileIsUploading = true;
    this.recrutementService.uploadFile('candidatures/cvs/' ,file).then(
      (url: string) => {
        this.cvFileUrl = url;
        this.cvFileIsUploading = false;
        this.cvFileUploaded = true;
      }
    );
  }

  detectFiles(event) {
    this.onUploadFile(event.target.files[0]);
  }
*/
  closeModal(): void {
    this.renderer.setStyle(this.modalIndicationForm.nativeElement, 'display', 'none');
    this.onBack();
  }

  onBack() {
    this.router.navigate(['/offre-emploi']);
  }

}
