import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HomeComponent } from './pages/home/home.page';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.page';
import { HomeService } from './services/home.service';
import { ContactComponent } from './components/contact/contact.component';
import { RealisationComponent } from './components/realisation/realisation.component';
import { ClientComponent } from './components/client/client.component';
import { TeamComponent } from './components/team/team.component';
import { AboutComponent } from './components/about/about.component';
import { HeaderComponent } from './components/header/header.component';
import { NewsComponent } from './components/news/news.component';
import { CookieService } from "angular2-cookie/services/cookies.service";



const homeRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent},
  { path: 'team', component: TeamComponent}
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(homeRoutes)
  ],
  declarations: [
    HomeComponent,
    MentionsLegalesComponent,
    ContactComponent,
    RealisationComponent,
    ClientComponent,
    TeamComponent,
    AboutComponent,
    HeaderComponent,
    NewsComponent
  ],
  providers: [
    HomeService,
    CookieService
  ]
})
export class HomeModule { }
