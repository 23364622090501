import { Component, OnInit,Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Client } from '../../../../shared/models/client.model';
import { HomeService } from '../../services/home.service';



@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  clients: Client[];
  alwaysTrueForLi : boolean = true;
  isBrowser;
  isMobile:boolean=false;


  constructor(private homeService: HomeService,  @Inject(PLATFORM_ID) private platformId) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.homeService.getClients().subscribe(
      clients => {
        this.clients = clients;
      }
    );
    if(this.isBrowser){
      this.isMobile = /Android|iPhone|BlackBerry/i.test(window.navigator.userAgent);
    }
    console.log("Testing if website is on mobile");
      console.log(this.isMobile)
  }

}
