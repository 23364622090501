import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { IpServiceService } from '../../../../ip-service.service';
import { Observable } from 'rxjs';
// import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  private cookieValue: string;
  private ipAdress: string;
  constructor(private http: HttpClient, private ip: IpServiceService) {

  }

  public ngOnInit(): void {
    //this.cookieService.set('accept-cookies',(this.getIPAddress()+""));
    //var cookieValue = this.cookieService.get('accept-cookies');
  }



}
