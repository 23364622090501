import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../../services/home.service';
import { ReseauxSociaux } from '../../../../shared/models/reseauxSociaux.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  reseauxSociaux: ReseauxSociaux[];
  size: number;
  urlPath: string;

  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.url.subscribe(
      urlSegment => {
        this.urlPath = urlSegment[0].path;
        this.homeService.getReseauxSociaux().subscribe(
          reseauxSociaux => {
            this.reseauxSociaux = reseauxSociaux;

            this.size = 0;

            for (let ix = 0 ; ix < reseauxSociaux.length ; ix++){
              if (reseauxSociaux[ix].visible == 0)
                this.size++;
            }
          }
        );
      }
    );
  }
}
