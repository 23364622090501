import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2, ViewChildren, QueryList, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { Nouvelle } from '../../../../shared/models/nouvelle.model';
import { take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit{

  nouvelles: Nouvelle[];
  slideIndex: number = 0;
  fragment: string;
  idInterval: any;
  timeSlider  : number = 5000;
  @ViewChildren('mySlides') mySlides:QueryList<ElementRef>;
  isBrowser;

  constructor(
    private homeService: HomeService,
    public renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId
  ) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.homeService.getNouvelles().subscribe(
      nouvelles => {
        // Display the last database entries (news) in first
        this.nouvelles = nouvelles.reverse();
      }
    );

    if(this.isBrowser){
      this.idInterval = setInterval(()=> {
        this.plusDivs(1);
      }, this.timeSlider);
    }
  }

  ngAfterViewInit(): void {
    //dans ngAfterViewInit pck showDiv fait apl à getElement();
    this.slideIndex = 0;

    // workaround to make first news appear properly.
    this.mySlides
      .changes
      .pipe(take(1))
      .subscribe(d=>{
        this.showDivs(this.slideIndex);
      });
  }

  plusDivs(plusOrMinus) {
    let tmpIndex = this.slideIndex + plusOrMinus;

    if (tmpIndex >=  this.mySlides.length) {
      this.slideIndex = 0
    }
    else if (tmpIndex < 0) {
     this.slideIndex =  this.mySlides.length-1;
    }
    else {
      this.slideIndex = tmpIndex;
    }

    this.showDivs(this.slideIndex);
  }
  
  showDivs(slideIndexToShow:number) {
    this.mySlides.forEach(
      (item,index,array) => {
        if (slideIndexToShow == index) {
          //item.nativeElement.style.display ='block';
          this.renderer.setStyle(item.nativeElement, 'display', 'block'); 
        }
        else {
          //item.nativeElement.style.display ='none';
          this.renderer.setStyle(item.nativeElement, 'display', 'none'); 
        }
      });
  }

  mouseEnter(){
    if(this.isBrowser){
      clearInterval(this.idInterval);
    }
  }

  mouseLeave() {
    if(this.isBrowser){
      this.idInterval = setInterval(()=> {
        this.plusDivs(1);
      }, this.timeSlider);
    }
  }
}
