import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { NavigationBarComponent } from './shared/components/navigation-bar/navigation-bar.component';
import { Routes, RouterModule } from '@angular/router';
import { RecrutementModule } from './modules/recrutement/recrutement.module';
import { HomeModule } from './modules/home/home.module';
import { CookieService, CookieOptions  } from "angular2-cookie/core";

// import { CookieService } from 'ngx-cookie-service';

const appRoutes: Routes = [
  { path: '',   redirectTo: '/home', pathMatch: 'full' }, 
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    NotFoundComponent
  ],
  imports: [
    // Add .withServerTransition() to support Universal rendering.
    // The application ID can be any identifier which is unique on the page.
    BrowserModule.withServerTransition({appId: 'idigao-website-ssr'}),
    HomeModule,
    RecrutementModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "top",
      anchorScrolling: 'enabled',
      onSameUrlNavigation : "reload",
      useHash: true
    })
  ],
  providers: [ { provide: CookieOptions, useValue: {} }],
  bootstrap: [AppComponent]
})
export class AppModule { }
