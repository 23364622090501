import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobOfferListComponent } from './pages/job-offer-list/job-offer-list.component';
import { Routes, RouterModule } from '@angular/router';
import { RecrutementService } from './services/recrutement.service';
import { JobOfferViewComponent } from './pages/job-offer-view/job-offer-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';

const recrutementRoutes: Routes = [
  { path: 'offre-emploi', component: JobOfferListComponent },
  { path: 'offre-emploi/view/:key', component: JobOfferViewComponent }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forChild(recrutementRoutes)
  ],
  declarations: [
    JobOfferListComponent,
    JobOfferViewComponent
  ],
  providers: [
    RecrutementService
  ]
})
export class RecrutementModule { }
