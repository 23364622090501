import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { Membre } from '../../../../shared/models/membre.model';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit{
  //les images des membres doivent avoir un ration (Largeur/hauteur) de 0.625 environs
  membres: Membre[];
  size: number;
  defaultAvatarHomme: string = '../../../../assets/img/idigao/avatar-homme.jpg';
  defaultAvatarFemme: string = '../../../../assets/img/idigao/avatar-femme.jpg';
  urlPath: string;

  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // this.homeService.getConf().subscribe(
    //   websiteconf => {
    //     // On considere que le nombre de membres a afficher est le premier du tableau
    //     this.size = websiteconf[0].iwS_NbMembresRange;
    //   }
    // );
    this.size = 3;
    this.route.url.subscribe(
      urlSegment => {
        this.urlPath = urlSegment[0].path;
        this.homeService.getMembres().subscribe(
          membres => {
            this.membres = membres;

            if(this.urlPath=='home'){
              //this.membres = this.membres.slice(0,3);
              // TODO Pouvoir avoir un affichage du nombre de membres, sur l'accueil, personnalisable
              //this.membres = this.membres.slice(0,4);
              // Tentative mais non concluant ->
              this.membres = this.membres.slice(0, this.size);
            }
          }
        );
      }
    );
  }

  // openModal(index: number){
  //   this.modalMember = this.membres[index];
  //   let modalBoxElement = document.getElementById("memberModal");
  //   if(modalBoxElement) modalBoxElement.style.display = "block";
  // }

}
