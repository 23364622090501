import { Component, OnInit, ViewChild, Renderer2, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {
  
  @ViewChild('mySideBar') mySidebar;
  private activeSiteSection: string;

  constructor(
    private renderer: Renderer2,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd ) {
          this.SiteURLActiveCheck(event);
      }
    });
  }

  /*
  @HostListener("window:scroll", [])
  onScroll(): void {
    //console.log(window.scrollY);
    console.log(document.getElementById("about").getBoundingClientRect().top);
  }
  */

  ngOnInit() {}

  private SiteURLActiveCheck(event: NavigationEnd): void {
    if (event.url.indexOf('#about') !== -1) {
        this.activeSiteSection = 'about';
    } else if (event.url.indexOf('#team') !== -1) {
        this.activeSiteSection = 'team';
    } else if (event.url.indexOf('#client') !== -1) {
        this.activeSiteSection = 'client';
    } else if (event.url.indexOf('#work') !== -1) {
      this.activeSiteSection = 'work';
    }  else if (event.url.indexOf('#news') !== -1) {
      this.activeSiteSection = 'news';
    }  else if (event.url.indexOf('#contact') !== -1) {
      this.activeSiteSection = 'contact';
    } else if (event.url.indexOf('offre-emploi') !== -1) {
      this.activeSiteSection = 'offre-emploi';
    } else {
        this.activeSiteSection = '';
    }
  }

  //utiliser dans le template
  isSectionActive(section: string): boolean {
      return section === this.activeSiteSection;
  }

  // Toggle between showing and hiding the sidebar when clicking the menu icon
  w3_open() {
    if(this.mySidebar && this.mySidebar.nativeElement.style.display === 'block'){
      this.renderer.setStyle(this.mySidebar.nativeElement, 'display', 'none');
    }else{
      this.renderer.setStyle(this.mySidebar.nativeElement, 'display', 'block');
    }       
  }
  
  // Close the sidebar with the close button
  w3_close() {
    if(this.mySidebar)
      this.renderer.setStyle(this.mySidebar.nativeElement, 'display', 'none');
  }

}
