import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  stars: Array<any> = []; // Array that contains the stars
  FPS : number = 30;      // Frames per second
  //x: number = 150;        // Number of stars  -- cf method getNbStarsToDisplay
  mouse = { x: 0, y: 0 }; // mouse location
  scrollY: number = 0;
  @ViewChild('myCanvas') canvas: ElementRef;
  ctx: CanvasRenderingContext2D;
  isMobile:boolean=false;
  isBrowser;


  constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if(this.isBrowser){
      this.isMobile = /Android|iPhone|BlackBerry/i.test(window.navigator.userAgent);
    }

   }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(!this.isMobile){
      this.canvas.nativeElement.width = event.target.innerWidth;
      this.canvas.nativeElement.height = event.target.innerHeight;
      this.stars = [];
      let surface = event.target.innerWidth*event.target.innerHeight;
      let nbStarToDisplay = this.getNbStarsToDisplay(surface);
      // Push stars to array
      for (var i = 0; i < nbStarToDisplay; i++) {
        this.stars.push({
          x: Math.random() * this.canvas.nativeElement.width,
          y: Math.random() * this.canvas.nativeElement.height,
          radius: Math.random() * 1 + 1,
          vx: Math.floor(Math.random() * 50) - 25,
          vy: Math.floor(Math.random() * 50) - 25
        });
      }
    }

  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.scrollY = window.scrollY;
  }

  ngAfterViewInit(): void {
    if(!this.isMobile && this.isBrowser) {
      this.initCanvas();
    }
  }

  initCanvas() {

    this.ctx = (<HTMLCanvasElement>this.canvas.nativeElement).getContext('2d');

    this.canvas.nativeElement.width = window.innerWidth;
    this.canvas.nativeElement.height = window.innerHeight;

    let nbStarToDisplay = this.getNbStarsToDisplay(this.canvas.nativeElement.width*this.canvas.nativeElement.height);

    // Push stars to array
    for (var i = 0; i < nbStarToDisplay; i++) {
      this.stars.push({
        x: Math.random() * this.canvas.nativeElement.width,
        y: Math.random() * this.canvas.nativeElement.height,
        radius: Math.random() * 1 + 1,
        vx: Math.floor(Math.random() * 50) - 25,
        vy: Math.floor(Math.random() * 50) - 25
      });
    }

    this.tick();

  }

  onCanvasMouseMove(e: MouseEvent){
    this.mouse.x = e.clientX;
    this.mouse.y = e.clientY + this.scrollY;
  }

  onCanvasClick(e: MouseEvent){
    this.stars.push({
      x: e.clientX,
      y: e.clientY + this.scrollY,
      radius: Math.random() * 1 + 1,
      vx: Math.floor(Math.random() * 50) - 25,
      vy: Math.floor(Math.random() * 50) - 25
    });
  }

  // Draw the scene
  draw() {
    this.ctx.clearRect(0,0,this.canvas.nativeElement.width,this.canvas.nativeElement.height);

    //this.ctx.globalCompositeOperation = "lighter";
    this.ctx.globalCompositeOperation = "destination-over";

    for (var i = 0, x = this.stars.length; i < x; i++) {
      var s = this.stars[i];

      this.ctx.fillStyle = "#fff";
      this.ctx.beginPath();
      this.ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
      this.ctx.fill();
      this.ctx.fillStyle = 'black';
      this.ctx.stroke();
    }

    this.ctx.beginPath();
    for (var i = 0, x = this.stars.length; i < x; i++) {
      var starI = this.stars[i];
      this.ctx.moveTo(starI.x,starI.y);
      if(this.distance(this.mouse, starI) < 150) {
        this.ctx.lineTo(this.mouse.x, this.mouse.y);
      }

      for (var j = 0, x = this.stars.length; j < x; j++) {
        var starII = this.stars[j];
        if(this.distance(starI, starII) < 150) {
          //ctx.globalAlpha = (1 / 150 * distance(starI, starII).toFixed(1));
          this.ctx.lineTo(starII.x,starII.y);
        }
      }
    }
    this.ctx.lineWidth = 0.09;
    this.ctx.strokeStyle = 'white';
    this.ctx.stroke();
  }

  distance( point1, point2 ){
    var xs = 0;
    var ys = 0;

    xs = point2.x - point1.x;
    xs = xs * xs;

    ys = point2.y - point1.y;
    ys = ys * ys;

    return Math.sqrt( xs + ys );
  }

  // Update star locations
  update() {
    for (var i = 0, x = this.stars.length; i < x; i++) {
      var s = this.stars[i];

      s.x += s.vx / this.FPS;
      s.y += s.vy / this.FPS;

      if (s.x < 0 || s.x > this.canvas.nativeElement.width) s.vx = -s.vx;
      if (s.y < 0 || s.y > this.canvas.nativeElement.height) s.vy = -s.vy;
    }
  }

  // Update and draw
  tick() {
    this.draw();
    this.update();
    requestAnimationFrame(this.tick.bind(this)); // bind() sinon this sera null au deuxieme appel
  }

  getNbStarsToDisplay(surface: number): number{
    let nbStarToDisplay = 0;

    if(surface <= 100000)
      nbStarToDisplay = 30;
    else if(surface > 100000 && surface <= 300000)
      nbStarToDisplay = 50;
    else if(surface > 300000 && surface <= 500000)
      nbStarToDisplay = 70;
    else if(surface > 500000 && surface <= 1000000)
      nbStarToDisplay = 90;
    else if(surface > 1000000 && surface <= 1500000)
      nbStarToDisplay = 120;
    else if(surface > 1500000 && surface <= 2000000)
      nbStarToDisplay = 200;
    else
      nbStarToDisplay = 200;

    return nbStarToDisplay;
  }


}
