import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subscriber } from 'rxjs';
import { Membre } from '../../../shared/models/membre.model';
import { Nouvelle } from '../../../shared/models/nouvelle.model';
import { Client } from '../../../shared/models/client.model';
import { Realisation } from '../../../shared/models/realisation.model';
import { ReseauxSociaux } from '../../../shared/models/reseauxSociaux.model';
import { WebSiteConf } from '../../../shared/models/idiwebsiteconfig.model';
import { Contact } from '../../../shared/models/contact.model';
import { HttpClient } from '@angular/common/http';
import { shareReplay, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
// import * as newsFromFile from "../../../../../../IDIWebSiteJsonFilesQualif/News.json";
// import * as membresFromFile from "../../../../../../IDIWebSiteJsonFilesQualif/Membres.json";
// import * as clientsFormFile from "../../../../../../IDIWebSiteJsonFilesQualif/Clients.json";
// import * as reseauxSociauxsFromFile from "../../../../../../IDIWebSiteJsonFilesQualif/ReseauxSociaux.json";

@Injectable()
export class HomeService {


  private sqlDbUrl = "./assets/";
  private newsUrl           = this.sqlDbUrl + 'News.json';
  private clientsUrl        = this.sqlDbUrl + 'Clients.json';
  private membresUrl        = this.sqlDbUrl + 'Membres.json';
  // //private realisationsUrl   = this.fireDbUrl + 'realisations.json';
  private reseauxSociauxUrl = this.sqlDbUrl + 'ReseauxSociaux.json';
  // private wbConfUrl         = this.sqlDbUrl + 'idiwebsiteconfig';



  // membresJsonData:  Membre[]  =  (membresFromFile as any).default;
  // newsJsonData: Nouvelle[] = (newsFromFile as any).default;
  // clientsJsonData: Client[] = (clientsFormFile as any).default;
  // reseauxSocieauxJsonData: ReseauxSociaux[] = (reseauxSociauxsFromFile as any).default;

  constructor( private http: HttpClient) {

   }

  // getConf(): Observable<WebSiteConf[]> {
  //   return this.http.get<WebSiteConf[]>(this.wbConfUrl);
  // }

  getMembres():  Observable<Membre[]> {
    return this.http.get<Membre[]>(this.membresUrl);
    // return of(this.membresJsonData);
  }

  getNouvelles(): Observable<Nouvelle[]>{
    return this.http.get<Nouvelle[]>(this.newsUrl);       // Exemple d'appelle service sans mise en cache
    // return of(this.newsJsonData);
  }

  getClients(): Observable<Client[]>{
    return this.http.get<Client[]>(this.clientsUrl);
    // return of(this.clientsJsonData);
  }
  getReseauxSociaux(): Observable<ReseauxSociaux[]>{
    return this.http.get<ReseauxSociaux[]>(this.reseauxSociauxUrl);
    // return of(this.reseauxSocieauxJsonData);
  }
}
