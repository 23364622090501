import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { OFFRESEMPLOI } from '../../../shared/mocks/mock-offres-emploi';
import { OffreEmploi } from '../../../shared/models/offreEmploi.model';
import { Candidature } from '../../../shared/models/candidature.model';

// import * as offresEmploiFromFile from "../../../../assets/OffresEmploi.json"; 
import { filter, map } from 'rxjs/operators';

@Injectable()
export class RecrutementService {
  offres: OffreEmploi[] = [];
  //  private sqlDbUrl = 'http://api2016d01:2078/api/OffresEmploi';
  private sqlDbUrl = "./assets/OffresEmploi.json";
  //  offresEmploisJsonData: OffreEmploi[] = (offresEmploiFromFile as any).default;
   offresEmploisJsonData: OffreEmploi[] =[];
  constructor(
    private http: HttpClient
  ) { }

  getOffresEmploi(): Observable<OffreEmploi[]> {
    return this.http.get<OffreEmploi[]>(this.sqlDbUrl);
    // return of(this.offresEmploisJsonData);
  }

  getOffreEmploi(id: number): OffreEmploi{
    /*
    let offre: OffreEmploi;
    Object.keys(OFFRESEMPLOI).forEach(k => {
      if(k==key) offre = OFFRESEMPLOI[k];
    });
    return of(offre);
    */
  //   this.getOffresEmploi().subscribe(offresEmplois => this.offres = offresEmplois);
  //   console.log(this.offres);
  //  return this.http.get<OffreEmploi[]>(this.sqlDbUrl)
  //         .pipe(map(txs => txs.find(txs => txs.id === id)));
    this.getOffresEmploi().subscribe(_offres => this.offresEmploisJsonData = _offres);

    return this.offresEmploisJsonData.find(x => x.id == id);
    // return of(this.offresEmploisJsonData.find(x => x.id == id));
  }

  guid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

}
