import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecrutementService } from '../../services/recrutement.service';
import { OffreEmploi } from '../../../../shared/models/offreEmploi.model';


@Component({
  selector: 'app-job-offer-list',
  templateUrl: './job-offer-list.component.html',
  styleUrls: ['./job-offer-list.component.css']
})
export class JobOfferListComponent implements OnInit {

  year:number = new Date().getFullYear();

  objectKeys = Object.keys;
  offresEmploi: OffreEmploi[];

  constructor(
    private recrutementService: RecrutementService,
    private router: Router
  ) { }

  ngOnInit() {
    this.recrutementService.getOffresEmploi().subscribe(
      offresEmploi => this.offresEmploi = offresEmploi
    );
  }

  handleClickJO(id: string): void{
    this.router.navigate(['/offre-emploi', 'view', id]);
  }

}
